@value colorWhite, colorGrayDog, colorWetAsphalt, colorGrey50 from '~Styles/vars/colors.css';

:root {
  --time: 0.1s;
  --ease: ease-in;
}

.root {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid colorGrey50;
  background: colorWhite;
  border-radius: 4px;
  transition: background var(--time) var(--ease), border var(--time) var(--ease),
    color var(--time) var(--ease);

  &:hover,
  &:focus {
    border-color: colorGrayDog;
  }
}

.icon {
  size: 24px;
  display: block;
  flex-shrink: 0;
  color: colorGrey50;
}

.input {
  size: 100%;
  font-size: 14px;
  line-height: 24px;
  color: colorWetAsphalt;
  outline: none;
  background-color: colorWhite;
  position: relative;
  box-sizing: border-box;

  transition: background var(--time) var(--ease), color var(--time) var(--ease);

  &::placeholder {
    color: colorGrayDog;
  }
}

.right {
  display: flex;
}

.left {
  .icon {
    order: 1;
    margin-right: 8px;
  }

  .input {
    order: 2;
  }
}

.clear {
  padding: 0;
  display: none;
}

.hasText,
.active {
  .icon {
    display: none;
  }

  .input {
    order: 2;
  }

  .clear {
    order: 3;
    display: flex;
  }
}

@value colorWhite from '~Styles/vars/colors.css';

.drawer {
  background-color: colorWhite;
}

.inDropdown {
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
}

@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
}

.details {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.detail {
  display: flex;
  gap: 4px;
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      size: 2px;
      background: colorGrey100;
      border-radius: 50%;
      margin: 0 12px;
    }
  }
}

.names {
  flex: 1;
}

.campaignNameWrap {
  display: flex;
  gap: 8px;
  padding-bottom: 8px;
}

.line {
  size: 100% 1px;
  background: #ededed;
}

.campaignInfo {
  display: flex;
  align-items: center;
  margin: 8px 0 12px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.campaignName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.paused {
  color: colorGrey100;
  margin-left: 20px;
}

.brandLogo {
  img {
    border-radius: 8px;
    border: 1px solid #ededed;
    box-sizing: border-box;
  }
}

.platform {
  height: 20px;

  svg {
    size: 20px;
  }
}

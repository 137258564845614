@value colorPurple40 from '~Styles/vars/colors.css';

.root {
  background-color: colorPurple40;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 8px;
  gap: 8px;
}

.icon {
  margin-top: 4px;
}

.textContainer {
  display: flex;
  flex-direction: column;
}
@value colorPurple100, colorGrey100, colorGrey10 from '~Styles/vars/colors.css';

.subtitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
}

.root {
  background-color: white;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
}

.dataContainer {
  padding: 8px 0;
  display: flex;
  width: 262px;
}

.actionContainer {
  width: 54px;
  display: flex;
  align-items: center;
}

.countersContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  flex: 1;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.infoItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  max-width: 256px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: colorGrey100;
}

.actionsMenu {
  width: 200px;
}

.listLabel {
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 4px;
  display: flex;
}

.listButton {
  width: 100%;
}

.stateTag {
  cursor: pointer;
}
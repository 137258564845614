@value colorConcrete, colorGrayDog, colorWetAsphalt, colorGrey50, colorWhite from '~Styles/vars/colors.css';

.root {
  border-top: solid 1px colorGrey50;
}

.field {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 12px 20px;
  gap: 20px;
}

.text {
  width: 100%;
  color: colorWetAsphalt;
  resize: none;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;

  &::placeholder {
    color: colorGrayDog;
  }
}

.send {
  display: flex;
  cursor: pointer;
}

.content {
  width: 100%;
  align-self: center;
}

.button {
  letter-spacing: 0.8px;
  margin-right: 12px;
}

.isMobile {
  min-height: 51px;
  border: none;
  border-top: solid 1px colorConcrete;
  align-items: center;

  .send {
    height: 51px;
    align-items: center;
    margin-bottom: 0;
  }

  .attach {
    margin-bottom: 17px;
    width: 27px;
  }
}

.attach {
  flex-shrink: 0;
  margin-right: 20px;
}

.drawer {
  display: flex;
  flex-direction: column;
  background-color: colorWhite;
  border-radius: 24px;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;

  .buttonContainer {
    display: flex;
    gap: 8px;
    margin: auto 0 0;
    width: 100%;
  }
}

.newView {
  border: 1px solid colorGrey50;
  border-radius: 16px;

  .field {
    padding: 21px 20px 22px 16px;
  }
}

@value colorWhite from '~Styles/vars/colors.css';

.content {
  background: colorWhite;
  width: 471px;
  border-radius: 22px;
  padding: 20px;
  box-sizing: border-box;
}

.drawer {
  width: unset;
  height: unset;
}

.title {
  margin-bottom: 4px;
}

.descr {
  font-size: 16px;
}

.img {
  margin-bottom: 24px;
}

.details {
  text-align: center;
  margin-bottom: 27px;
}

.closeWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.buttons {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.iconWrap {
  text-align: center;
  margin-bottom: 24px;
}

.icon {
  color: #cc2e9f;
}

@value (  colorGrey10, colorPink100, colorWhite ) from '~Styles/vars/colors.css';

.header {
  border-bottom: 1px solid colorGrey10;
}

.preloaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.title {
  text-align: center;
}

.imageWrapper {
  width: 100%;
  max-width: 797px;
  margin-top: 32px;
  margin-bottom: 16px;

  img {
    width: 100%;
  }
}

.textWrapper {
  max-width: 748px;
}

.titleBrandName {
  color: colorPink100;
}

.subtitle {
  margin-bottom: 20px;
}

.subtitleBrandName {
  font-weight: 700 !important;
}

.reasonsTitle {
  margin-bottom: 16px;
}

.final {
  margin-top: 20px;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colorWhite;
  width: 100%;
  padding: 16px;
  position: fixed;
  bottom: 0;
}

.footerBtn {
  max-width: 720px;
  margin: 0 16px;
}

:global .isMobile {
  :local .logo,
  :local .header {
    display: none;
    height: 0;
  }
  :local .wrapper {
    padding: 16px 16px 140px;
  }
}

@value colorGrey50 from '~Styles/vars/colors.css';

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

.root {
  display: flex;
  gap: 16px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}


.headerItem {
  width: 100%;
  height: 56px;
  background: colorGrey50;
  border-radius: 16px;
  @mixin skeletonLoading;
  margin-bottom: 16px;
}
.item {
  size: 100% 80px;
  background: colorGrey50;
  border-radius: 16px;
  @mixin skeletonLoading;
}

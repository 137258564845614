@value colorGrey50, colorRed from '~Styles/vars/colors.css';

.root {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 40px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  &.notValid {
    border: 1px solid colorRed;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .fullView {
    display: none;
  }
}

.opened {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 1px solid colorGrey50;
  padding: 40px;

  .preview {
    display: none;
  }

  .fullView {
    display: block;
  }
}

.controls {
  display: flex;
  gap: 20px;
  padding-top: 32px;
  border-top: 1px solid colorGrey50;
}

.contentType {
  flex-basis: 130px;
}

.noPostingWarning {
  margin-top: 8px;
}

.dosTooltip {
  margin-bottom: 16px;
  margin-top: 8px;
}

@value colorGrey50, colorDark100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 2;
  border-radius: 6px;
  border: 1px solid colorGrey50;
  overflow: hidden;
  height: 26px;
  width: 54px;


  .item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;

      > div {
        height: 16px;
      }

      i {
        opacity: 0.5;
      }
    }

    &.active {
      background-color: colorDark100;
    }
  }
}
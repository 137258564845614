@value colorGrey10, colorOcean100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  border-radius: 8px;
  background: white;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 16px;
}

.row {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  background: colorGrey10;
  grid-template-columns: 296px 124px 124px 124px 124px 124px 1fr 54px;
  padding: 8px 16px;
  align-items: center;
}

.stepTitleText {
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    margin-right: 16px;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: colorOcean100;
  }
}

.status {
  width: fit-content;
}
@value desktopMinWidth from '~Styles/vars/query.css';
@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  background: colorGrey10;
  flex: 1;
}

.container {
  display: flex;
  flex-wrap: wrap;
  width: desktopMinWidth;
  margin: 0 auto;
  box-sizing: border-box;
}

.preloader {
  margin: 40px auto;
}

@value colorWhite, colorConcrete, colorBase, colorPurple100, colorGrey10, colorDark100 from '~Styles/vars/colors.css';

.root {
  position: relative;
  vertical-align: top;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    z-index: 10;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

.content {
  height: 100%;
  background: colorWhite;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.container {
  height: 100%;
  position: relative;
  background: colorConcrete;
  flex-grow: 1;
}

.details {
  border-radius: 0 0 4px 4px;
  flex-shrink: 0;
}

.info {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
}

.avatarWrap {
  position: relative;
  max-height: 48px;
  margin-right: 10px;
}

.user {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.link {
  overflow: hidden;
}

.userName {
  max-width: 100%;
  display: flex;
  align-items: center;
}

.names {
  flex-grow: 1;
  overflow: hidden;
  padding-right: 10px;
}

.name {
  margin-right: 11px;
}

.userLinkWrap {
  max-width: 100%;
  overflow: hidden;
}

.userLink {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link,
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;
  cursor: default;
}

.marks {
  display: flex;
  background-color: colorGrey10;
  padding: 16px;
  height: 68px;
  box-sizing: border-box;
  justify-content: space-between;
}

.mark {
  text-align: center;
  flex-grow: 1;
}

.priceTooltip {
  position: relative;
}

.addedToMediaplan {
  box-shadow: 0 0 0 2px colorBase, 0 1px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 0 0 2px colorBase;
  }
}

.hiddenState {
  top: 20px;
  right: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: colorWhite;
  border-radius: 4px;
}

.viewed {
  .details {
    opacity: 0.5;
  }
}

.badges {
  display: flex;
  gap: 4px;

  .badge {
    padding: 0;
  }
}

:global .show {
  opacity: 1 !important;
}

.tooltip {
  background: colorWhite;
  padding: 20px !important;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-wrap;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 8px 20px rgba(0, 0, 0, 0.1);
}

.badgeIcon {
  size: 16px;
}

.registartionMark {
  margin-left: 4px;
}

.blockedName {
  position: relative;
}

.blured {
  padding-left: 3px;
  filter: blur(4px);
  user-select: none;
}

.lockIcon {
  top: 4px;
  position: absolute;
  color: colorPurple100;
  cursor: pointer;
  z-index: 1;
}

.checked {
  border: 2px solid colorPurple100;
}

.root {
  display: block;
  border: 1px solid #d5d8d9;
  padding: 12px 15px;
  border-radius: 12px;
  background: white;

  .username {
    color: #a72682;
    font-size: 16px;
    font-weight: 800;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .createdAt {
    color: #63696a;
    font-size: 14px;
  }
}

.usernameWrap {
  display: flex;
  gap: 4px;
  width: 190px;
  overflow: hidden;
}

.ava {
  box-shadow: 0px 0px 3.89px 0px #e0e2e2;
  border: 2px solid white;
  box-sizing: border-box;
}

.avatarWrap {
  position: relative;
  size: 39px;
}

.platform {
  size: 18px;
  position: absolute;
  bottom: -4px;
  right: -4px;
  @mixin center;
  border-radius: 50%;
  background: white;
}

.platformImg {
  size: 12px;
}

.details {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}

.poster {
  size: 248px 352px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-color: #f5f6f6;
}

.gotoChatLink {
  width: 100%;
}

.gotoChat {
  border: 1px solid #001417;
  box-sizing: border-box;
  height: 34px;
  width: 100%;
  margin-top: 12px;
}

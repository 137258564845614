@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  text-align: center;
  margin: 0 auto;
}

.img {
  margin-bottom: 28px;
}

.btn {
  margin: 12px auto;
}

.title {
  margin-bottom: 4px;
}

.content {
  margin: 48px auto 0;
  width: 462px;
}

.inviteBtn {
  border: 1px solid colorGrey50;
  margin: 12px auto;
}

@value colorDark100, colorGrey50, colorPink100, colorWhite from '~Styles/vars/colors.css';

.root {
  height: 70px;
  width: 70px;
  position: relative;

  &.round {
    img {
      border-radius: 50%;
    }
    flex-shrink: 0;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 13px;
  }

  .platformImg {
    position: absolute;
    bottom: -4px;
    right: -4px;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: colorWhite;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      height: 14px;
      width: 14px;
    }
  }
}

@value desktopMinWidth from '~Styles/vars/query.css';
@value colorGrey10, colorWhite from '~Styles/vars/colors.css';
@value headerHeight from '~Styles/vars/size.css';

.root {
  padding-top: 16px;
  position: sticky;
  top: headerHeight;
  z-index: 10;
  background: colorWhite;

  .tabs {
    padding: 8px 10px;
    background: #f0f1f2;
    border-radius: 12px;
    display: inline-flex;
  }

  &.isSticky {
    border-bottom: 1px solid #d5d8d9;
    background: colorWhite;

    .innerTabsWrap {
      background: colorWhite;
    }
  }
}

.topTabs {
  @mixin innerContainer;
}

.innerTabs {
  @mixin innerContainer;
  padding-top: 12px;
  padding-bottom: 16px;
}

.innerTabsWrap {
  background: colorGrey10;
}

@value colorPurple100, colorGrey100, colorGrey10, colorGrey50 from '~Styles/vars/colors.css';

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.drawerContent {
  flex: 1;
  overflow: auto;
}

.preloader {
  margin: 0 auto;
}

.btnContainer {
  display: flex;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 24px;

  .button {
    width: 100%;
  }
}

.input {
  margin-top: 8px;
  min-height: 72px;
}

.descr {
  margin: 16px 0;
  color: colorGrey100;
}

.email {
  color: colorPurple100;
}

.reasons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reason {
  background: colorGrey10;
  border: 1px solid colorGrey10;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &.active {
    border-color: colorGrey50;
    cursor: unset;
  }
}

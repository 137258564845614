@value colorPink100, colorWhite from '~Styles/vars/colors.css';

.root {
  min-width: 18px;
  height: 18px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 30px;
  background: colorWhite;
  @mixin center;
  box-sizing: border-box;

  &.isActive {
    background: #cc2e9f;
  }
}

.size-md {
  min-width: 24px;
  height: 24px;
}

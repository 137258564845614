@value colorGrey50, colorWhite, colorPink60 from '~Styles/vars/colors.css';

.root {
  background-color: white;
  border: 1px solid colorGrey50;
  position: relative;
  padding: 8px;
  box-sizing: border-box;
  min-width: 486px;
  display: flex;
  flex-direction: column;

  [class*='react-datepicker__header'] {
    background-color: colorWhite;
    border: none;
    padding: 0;
  }

  [class*='react-datepicker__day-names'] {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  [class*='react-datepicker__week'] {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
}

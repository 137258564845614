@value colorNewPink from '~Styles/vars/colors.css';

.title {
  color: #6d7478;
}

.unread {
  size: 8px;
  border-radius: 50%;
  background: #cc2e9f;
}

.notification {
  background-color: colorNewPink;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 31px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transform: translateX(-50%);
  border-radius: 4px;
  z-index: 5;
  .poly{
    position: absolute;
    bottom: 85%;
  }
}

.item {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;

  &.active {
    cursor: default;
    border-bottom-color: colorNewPink;
  }
}

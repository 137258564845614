@value (
  colorPink40,
  colorPink100
) from '~Styles/vars/colors.css';

.root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0 16px;
  padding: 16px 0;
  box-sizing: border-box;

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
  }
  .input {
    width: 420px;
  }

  .statusMenu {
    width: 240px;
  }

  .statusValue {
    width: 100px;
  }
}

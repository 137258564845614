@value colorWhite, colorGrey100, colorGrey10, colorGrey50, colorError100, colorSuccess100 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.container {
  margin: 24px auto 0;
  width: 516px;
  padding: 8px 16px;
  box-sizing: border-box;
  text-align: center;
}

.descr {
  margin-bottom: 15px;
}

.title {
  flex: 1;
  margin-bottom: 4px;
}

.shop {
  background: colorWhite;
  padding: 16px;
  display: flex;
  gap: 16px;
  border-radius: 16px;
  height: 72px;
  box-sizing: border-box;
  align-items: center;

  &.disabled {
    background: colorGrey10;
    color: colorGrey50;
    cursor: default;

    .icon,
    .title {
      color: colorGrey50;
    }
  }
}

.addNew {
  cursor: pointer;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.icon {
  color: colorGrey100;
}

.check {
  color: colorSuccess100;
}

.inDrawer {
  .shop {
    background: colorGrey10;
  }
}

.reauth {
  color: colorError100;
}

.error {
  color: colorError100;
}

.status {
  color: colorGrey100;
}

.details {
  flex: 1;
}

.stubImg {
  margin-bottom: 29px;
}

@value colorGrey100, colorPurple100, colorGrey50, colorSand40 from '~Styles/vars/colors.css';

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1;
  padding: 12px 16px;
}

.root {
  background-color: colorSand40;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;

  > div, a {
    display: flex;
    align-items: center;
    flex: 1;
    &:not(:first-child) {
      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: colorGrey50;
        margin-right: 16px;
      }
    }
    &:last-child {
      margin-right: 16px;
    }
  }
}

.counter {
  color: colorGrey100;
}


.root {
  display: flex;
  flex: 1;
  border-radius: 8px;
  width: 1148px;
  max-width: 1148px;
  padding: 0 16px;

  .outreach {
    width: 100%;
  }
  .invite {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    gap: 4px;
    padding: 64px;
    box-sizing: border-box;
  }
}
@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.wrap {
  width: 100%;
  position: relative;
  text-align: left;
}

.content {
  width: 100%;
  box-sizing: border-box;
  padding: 7px 12px;
  resize: none;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  @mixin field;
}

.root {
  width: 100%;
  resize: none;
  background: colorWhite;
}

.withMaxlength {
  opacity: 0.9;
}

.limit {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.multiple.withMaxlength {
  padding-bottom: 20px;
}

.preloader {
  position: absolute;
  top: 8px;
  right: 12px;
}

.loading {
  background: colorConcrete;
  border-color: colorConcrete;
}

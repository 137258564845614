@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  width: desktopMinWidth;
  margin: 0 auto;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
  }
}

.content {
  margin-top: 12px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.secton {
  margin-bottom: 24px;
}

@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
  

  > div {
    position: relative;
    width: 180px;
    &:not(:last-child) {
      margin-right: 32px;
      &:after {
        content: '';
        position: absolute;
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 30px;
        background-color: colorGrey50;
      }
    }
  }
}
@value colorGrey10 from '~Styles/vars/colors.css';

.header {
  display: grid;
  grid-template-columns: 304px 118px 118px 118px 118px 118px 1fr 54px;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 24px;
  gap: 8px;
}

.batchList {
  
}

.batch {
  background-color: white;
  border-radius: 8px;
  padding: 4px 16px !important;
  margin-bottom: 8px;
}
@value colorGrey100, colorGrey50, colorSun100, colorGrass100, colorSky100, colorPurple100, colorPink100, colorError100, colorWhite from '~Styles/vars/colors.css';

.root {
  width: 100%;
  min-height: 196px;
  background: colorWhite;
  border-radius: 16px;
  display: flex;
  position: relative;
  border: 1px solid colorWhite;
  overflow: hidden;

  &:hover {
    border: 1px solid colorGrey50;
  }
}

.infoDetails {
  display: flex;
  gap: 8px;
  align-items: center;
}

.content {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
}

.stage {
  display: flex;
  align-items: center;
  color: colorGrey100;

  &::before {
    content: '';
    size: 6px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 9px;
  }
}

.unsent {
  color: colorGrey50;
  &::before {
    border: 1px solid colorGrey50;
  }
}

.unread,
.read {
  &::before {
    background: colorGrey100;
  }
}

.accepted {
  &::before {
    background: colorSun100;
  }
}

.launched {
  &::before {
    background: colorGrass100;
  }
}

.content_submitted {
  &::before {
    background: colorSky100;
  }
}

.publishing_started {
  &::before {
    background: colorPurple100;
  }
}

.completed {
  &::before {
    background: colorPink100;
  }
}

.rejected {
  &::before {
    background: colorError100;
  }
}

.note {
  margin-top: 16px;
}

.noteInput {
  min-height: 69px;
}

.answer {
  margin-top: 16px;
}

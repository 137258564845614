@value colorDark100, colorPink80, colorError50 from '~Styles/vars/colors.css';

.componentContainer {
  position: fixed;
  bottom: 20px;
  width: fit-content;
}

.item {
  border-radius: 16px;
  padding: 16px 40px;
  width: fit-content;
  white-space: nowrap;
  
  position: fixed;
  bottom: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.dark {
    background: colorDark100;
    .text {
      color: white;
    }
  }

  &.red {
    background: colorError50;
    .text {
      color: colorDark100;
    }
  }

  .content {
    display: flex;

    .text {
      display: inline;
      margin-right: 10px;
    }
  }

  .cancel {
    margin-left: 10px;
    color: colorPink80;
  }
}

.item,
.componentContainer {
  &.left {
    left: 100px;
  }

  &.right {
    right: 100px;
  }

  &.center {
    left: 50%;
    transform: translateX(-50%);
  }
}

@import url('https://fonts.googleapis.com/css?family=DM+Sans:300,400,400i,500,700');

@font-face {
  font-family: 'DMSans';
  src: local('DMSans Bold'), local('DMSans-Bold'), url('DMSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'DMSans';
  src: local('DMSans Light'), local('DMSans-Light'), url('DMSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'DMSans';
  src: local('DMSans Medium'), local('DMSans-Medium'), url('DMSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'DMSans';
  src: local('DMSans'), url('DMSans.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'insense';
  src: url('insense.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@value newDesktopMinWidth, mobileMaxInnerWidth from '~Styles/vars/query.css';

.root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.container {
  @mixin newInnerContainer;
}

.title {
  padding: 16px 0;
}

.search {
  margin-top: 21px;
  margin-bottom: 37px;
}

.params {
  margin-top: 20px;
  margin-bottom: 20px;
}

.preloader {
  margin: 0 auto;
}

.countStub {
  height: 24px;
}
.root {
  display: block;
  width: 100%;
  min-height: 331px;
  box-sizing: border-box;
  border: 1px solid #d5d8d9;
  padding: 12px 15px;
  border-radius: 12px;
  background: white;
  display: flex;
  gap: 12px;

  .username {
    color: #a72682;
    font-size: 16px;
    font-weight: 800;
  }

  .createdAt {
    color: #63696a;
    font-size: 14px;
  }

  .rowTitle {
    font-size: 16px;
    font-weight: 400;
    color: #505555;
  }

  .rowValue {
    font-size: 16px;
    font-weight: 700;
    color: #001417;
  }
}

.poster {
  size: 216px 100%;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-color: #f5f6f6;
}

.usernameWrap {
  display: flex;
  gap: 4px;
}

.ava {
  box-shadow: 0px 0px 3.89px 0px #e0e2e2;
  border: 2px solid white;
  box-sizing: border-box;
}

.avatarWrap {
  position: relative;
  size: 39px;
}

.platform {
  size: 18px;
  position: absolute;
  bottom: -4px;
  right: -4px;
  @mixin center;
  border-radius: 50%;
  background: white;
}

.platformImg {
  size: 12px;
}

.details {
  display: flex;
  gap: 10px;
}

.stats {
  display: flex;
  flex: 1;
  gap: 12px;
  flex: 1;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.row {
  size: 100% 37px;
  background: #f5f6f6;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.spacer {
  flex: 1;
}

.control {
  border: 1px solid #001417;
  box-sizing: border-box;
  height: 34px;
  width: 100%;
  margin-top: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

@value colorDark100, colorGrey10, colorGrey50, colorGrey100, colorGrey20, colorPink100, colorPink60, colorWhite, colorPurple60 from '~Styles/vars/colors.css';

button {
  all: unset;
  cursor: pointer;
}

button:active {
  outline: none;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;
  padding: 11px 14px;
  transition: 0.3s all;
  background-color: colorWhite;
  color: colorDark100;
  border: 1px solid transparent;

  &[disabled] {
    cursor: default;
    opacity: 0.6;
  }

  &.withTextAnimation {
    .buttonText {
      display: none;
    }
    &:hover {
      .buttonText {
        display: block;
      }
    }
  }

  .textContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &.buttonTextByCenter {
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    &.buttonTextByLeftSide {
      width: 100%;
      text-align: left;
      justify-content: flex-start;
    }
    &.withCounter {
      padding-right: 32px;
    }
  }

  &.white.noHover:hover {
    background-color: colorWhite !important;
    color: colorDark100 !important;
  }

  &.black {
    background-color: colorDark100;
    color: colorWhite;

    &:hover {
      &:not(:disabled) {
        background-color: colorPink100;
        color: colorWhite;
      }
    }
  }

  &.black.noHover:hover {
    background-color: colorDark100 !important;
    color: colorWhite !important;
  }

  &.grey {
    background-color: colorGrey10;
    color: colorDark100;

    &[disabled] {
      border-color: transparent;
      color: colorGrey100;
    }

    &:hover {
      &:not(:disabled) {
        border-color: colorGrey50;
      }
    }
  }

  &.pink {
    background-color: colorPink100;
    color: colorWhite;

    .counter {
      color: colorWhite;
    }

    &:hover {
      &:not(:disabled) {
        background-color: colorPink60;
      }
    }
  }

  &.pink.noHover:hover {
    background-color: colorPink100 !important;
    color: colorWhite !important;
  }

  &.blur {
    background-color: colorGrey20;
    backdrop-filter: blur(6px);
    color: colorWhite;
    &:hover {
      color: colorDark100;
      background-color: colorWhite;
    }
  }

  .counter {
    color: colorGrey100;
    margin-left: 4px;
    margin-top: 1px;

    &.fixedCounterWidth {
      position: absolute;
      text-align: right;
      right: 32px;
      z-index: 1;
    }
  }

  &.fluid {
    flex: 1;
  }

  &.bordered {
    border: 1px solid colorGrey50;
    &.active {
      border-color: colorDark100;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
    .buttonText {
      cursor: pointer;
      text-wrap: inherit;
    }
  }

  &:hover {
    &:not(:disabled) {
      background-color: colorGrey10;
    }
  }

  .newBadge {
    padding: 4px 8px;
    border-radius: 99px;
    background: colorPurple60;
  }
}

.root {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;

  &.alter {
    padding: 8px 16px;
  }

  &.withoutHorizontalPadding {
    padding: 4px 0;

    &.alter {
      padding: 8px 0;
    }
  }
}

@value colorWhite, colorPurple100, colorDark100, colorPink60 from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.types {
  flex-direction: column;
}

.typeItem {
  align-items: flex-start !important;
}

.strategyControl {
  min-width: 224px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-height: 240px;
  box-sizing: border-box;
  &.small {
    min-height: auto;
  }
}

.upgradeTooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.goalItem {
  text-align: center;
  padding: 12px 20px;
}

.ttAuthWraning {
  top: 190px;
  right: -356px;
  position: absolute;
  background-color: colorDark100;
  padding: 20px;
  box-sizing: border-box;
  width: 340px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(82, 57, 196, 0.2);
  display: flex;
  flex-direction: column;
  gap: 4px;

  .title {
    color: colorPink60;
  }

  .link {
    text-decoration: underline;
    color: colorPink60;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    display: block;
    border-style: solid;
    border-width: 13px 10px 13px 0;
    border-color: transparent colorDark100 transparent transparent;
    transform: rotate(0deg);
    left: -10px;
  }
}

@value colorWhite, colorNewPink from '~Styles/vars/colors.css';

.title {
  color: #6d7478;
  cursor: pointer;
}

.active {
  cursor: default;
  display: flex;
  gap: 4px;
  align-items: center;
  background: colorWhite;
}

.item {
  padding: 10px 16px;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
}

.notification {
  background-color: colorNewPink;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 31px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transform: translateX(-50%);
  border-radius: 4px;
  .poly{
    position: absolute;
    bottom: 85%;
  }
}

.unread {
  size: 8px;
  border-radius: 50%;
  background: #cc2e9f;
}

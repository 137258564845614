@value headerHeight from '~Styles/vars/size.css';
@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
}

.navigationSkeleton {
  height: calc(100vh - headerHeight);

  width: 63px;
  background-color: white;
  border-right: 1px solid colorGrey50;

  @media (min-width: 1460px) {
    width: 205px;
  }
}
@value desktopMinWidth, newDesktopMinWidth from '~Styles/vars/query.css';
@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  flex: 1;
  display: block;
  min-width: desktopMinWidth;

  @media (max-width: newDesktopMinWidth) {
    padding-left: 64px;
  }
}
.preloader {
  height: 100vh;
  width: 100%;
  background-color: colorGrey10;
  display: flex;
  align-items: center;
  justify-content: center;
}

@value colorError50 from '~Styles/vars/colors.css';

.root {
  position: fixed;
  bottom: 0;
  left: calc(50% + 103px);
  transform: translateX(-50%);
  padding: 16px 16px;
  background-color: colorError50;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 120px;
  min-width: 66%;
  z-index: 1;
}

.text {
  white-space: nowrap;
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
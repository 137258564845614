@value colorOcean100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  background-color: white;

  .list {
    max-height: calc(100vh - 288px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
  }
  .actions {
    padding: 16px 0 0 0;
    display: flex;
    flex-direction: column;

    .saveButton {
      margin-top: 16px;
    }
  }
  .firstStepText {
    color: colorOcean100;
    margin-top: 16px;
  }
}
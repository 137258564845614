.loadButton {
  margin-top: 40px;
}

.overflowBlock {
  overflow: hidden;
  width: 100%;
}

.bar {
  display: grid;
  grid-template-columns: 140px 120px 136px 80px max-content;
  grid-gap: 20px;
  padding: 16px 20px;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  background-color: #ffffff;
  position: sticky;
  top: 50px;
  z-index: 10;
}

.item {
  display: grid;
  grid-template-columns: 140px 120px 136px 80px max-content;
  grid-gap: 20px;
  padding: 16px 20px;
  border-bottom: 1px solid #F2F2F2;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-color: #ffffff;
  }
}

.cuttedText {
  @mixin cuttedText;
}
@value colorGrey50, colorPurple40 from '~Styles/vars/colors.css';


.root {
  position: relative;
}
.buttonMenu {
  position: absolute;
  padding: 8px;
  border-radius: 8px;
  z-index: 1;
  background-color: white;
  border: 1px solid colorGrey50;
  width: 320px;
  bottom: 100%;
  min-height: 180px;
  
  flex-direction: column;
  padding-top: 16px;
  display: none;
  right: 0px;

  &.show {
    display: flex;
  }

  .menuInput {
    min-height: 57px;
    margin-bottom: 8px;

    &:first-child {
      margin-bottom: 16px;
    }
  }

  .label {
    top: -12px;
    padding: 0 4px;
    background-color: white;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .button {
    width: fit-content;
  }
}

.placeholderDescriptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  border-top: 1px solid #D5D8D9;

  .placeholderItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .placeholderItemColor {
      height: 20px;
      width: 20px;
      border-radius: 6px;
    
      &.placeholderItemHighlight {
        background-color: #FFF2C8;
      }
      &.placeholderItemVariable {
        background-color: colorPurple40;
      }
    }
    .placeholderItemText {
      font-size: 12px;
    }
  }
}
@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.disabled {
  opacity: 0.5;
}
.img {
  size: 15px;
}
.text {
  color: colorGrey100;
  margin-right: 4px;
  cursor: default;
  user-select: none;
}
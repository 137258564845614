@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

.input {
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  border: 1px solid colorGrey50;
}
@value colorGrey50, colorSky40 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badge {
  display: flex;
  margin-left: 20px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.buttonsGroup {
  display: flex;
  align-items: center;
  gap: 8px;

  .headerButton {
    padding: 12px 16px;

    :not(:first-child) {
      margin-left: 8px;
    }
  }
}

.userContent {
  .userInfoWrap {
    padding-bottom: 4px;
    border-bottom: 1px solid colorGrey50;

    .userInfo {
      padding: 12px 20px;
      display: flex;

      .names {
        margin-left: 12px;
      }
    }
  }

  .offer {
    margin-top: 8px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .img {
      margin-right: 12px;
    }

    .offerText {
      min-width: 151px;
    }
  }
}

.admin {
  top: 0;
  left: 50%;
  position: absolute;
  margin-left: -32px;
}

.item {
  display: flex;
  align-items: center;
}

.demo {
  background: colorSky40 !important;
  border-color: colorSky40 !important;
}

.notificationsBell {
  padding: 14px 16px;
  border: 1px solid transparent;
  max-height: 48px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.emptyImage {
  height: 180px;
  object-fit: contain;
  margin-bottom: 28px;
}

.button {
  margin-top: 12px;
}

.description {
  display: inline;
  cursor: default;
}
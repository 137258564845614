@value colorGrey10,colorGrey50,colorGrey100, colorPink100,colorDark100, colorError100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid colorGrey50;

  &.active {
    border-color: colorDark100;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
  }

  .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    .titleText {
      flex: 1;
    }
  }

  .label {
    height: 24px;
    width: 24px;

  }

  .button {
    padding: 0;
  }

  .deletePlaceholder {
    height: 48px;
    width: 54px;
  }

  .mailInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 4px;
  }
}

.delayInput {
  padding: 2px 16px 2px 12px;
  margin: 0 8px;
  max-height: 34px;
  border: 1px solid colorGrey100;
  border-radius: 6px;

  &.withArrow {
    padding-right: 4px;
    margin: 0;
  }
}

.mailOptionText {
  color: colorGrey100;
}

.delayValueList {
  background-color: white;
  width: 120px;
  max-height: 160px;
  overflow: auto;
}

.timeInput {
  padding: 2px 12px 2px 8px;
  margin: 0 8px;
  max-height: 34px;
  border: 1px solid colorGrey100;
  border-radius: 6px;
}

.timeValueList {
  background-color: white;
  width: 90px;
  max-height: 120px;
  overflow: auto;
}

.timeValueDescription {
  color: colorGrey100;
  margin-top: 4px;
  margin-bottom: 16px;
}

.addButton {
  color: colorGrey100;
  justify-content: flex-start;
  padding: 8px 0;
}

.mailOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mailOptionValue {
  margin-left: 4px;
}

.menu {
  width: 120px;
}